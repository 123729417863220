
import { NavLink } from "react-router-dom";
import HeaderLogo from "../../utils/Images/HeaderLogo";
import "./Header.scss";
import { useTranslation } from "react-i18next";
import UserIconPopup from "./UserIconPopup";
import { useState } from "react";
// import { tempAssessmentUId } from "../../../utils/Common/Constant";
const Header = () => {
  const { t, i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  { t("") }
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  return (
    <>
      <nav className="navbar navbar-expand-lg ">
        <div className="container-fluid logo-link">
          <HeaderLogo />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 link">
              {/* <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={`/${currentLanguage}/dashboard/`}
                >
                  Dashboard
                
                </NavLink>
              </li> */}


              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={`/${currentLanguage}/company-list`}
                >
                  Companies
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={`/${currentLanguage}/ticket/list`}
                >
                  Support Tickets
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={`/${currentLanguage}/payment`}
                >
                  Payment
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={`/${currentLanguage}/reported-questions`}
                >
                  Reported Questions
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={`/${currentLanguage}/feedback`}
                >
                  Feedback
                </NavLink>
              </li>
            </ul>
            <div className="profileIconSec" onClick={() => setIsPopupOpen(!isPopupOpen)}>
              <div className="profileIcon">
                <h3 className="profileFirstLetter">A</h3>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="borderBottomLine"></div>
      {
        isPopupOpen && (<UserIconPopup />)
      }
    </>
  );
};

export default Header;
