import { ReactNode } from "react";
import "./CustomModal.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { AssessmentButton } from "ui-components";
import CircleCrossIcon from "../../utils/Images/CircleCrossIcon";
interface ICustomModal {
  handleClose?: () => void;
  handleShow?: () => void;
  show: boolean;
  isSaveEnable?: boolean;
  isCloseEnable?: boolean;
  children: ReactNode;
  instructionHeadTitle?: ReactNode;
  saveButtonText?: string | undefined;
  handleSave?: () => void;
  isCloseDisable?: boolean;
  isSaveDisable?: boolean;
  isHeaderVisible?: boolean;
  isFooterVisible?: boolean;
  className?: string;
  isCrossBtn?: boolean;
}
const CustomModal: React.FC<ICustomModal> = ({
  handleClose = () => {},
  show,
  isSaveEnable,
  isCloseEnable,
  children,
  instructionHeadTitle = "",
  saveButtonText,
  handleSave = () => {},
  isCloseDisable = false,
  isSaveDisable = false,
  isHeaderVisible = true,
  isFooterVisible = true,
  className = "",
  isCrossBtn = false,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        className={`instructionPopupSection ${className}`}
        show={show}
        onHide={handleClose}
        centered
        size="xl"
        backdrop="static"
      >
        {isCrossBtn && (
          <div onClick={handleClose} className="popupCrossIcon">
            <CircleCrossIcon />
          </div>
        )}
        {isHeaderVisible && (
          <Modal.Header closeButton={false}>
            <Modal.Title>{instructionHeadTitle}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>{children}</Modal.Body>
        {isFooterVisible && (
          <Modal.Footer>
            {isSaveEnable && (
              <AssessmentButton
                onClick={handleSave}
                disabled={isSaveDisable}
                className="modalSaveCustomCss"
              >
                {saveButtonText ? saveButtonText : t("GENERAL.SAVE")}
              </AssessmentButton>
            )}
            {isCloseEnable && (
              <Button
                variant="secondary "
                onClick={handleClose}
                disabled={isCloseDisable}
              >
                {t("GENERAL.CLOSE")}
              </Button>
            )}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};
export default CustomModal;
