import { useNavigate } from "react-router-dom";
import "./UserIconPopup.scss";
import { useTranslation } from "react-i18next";
// interface props {
//     setIsPopupVisible: any;
// }
const UserIconPopup = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    return (
        <div className="main">
            <div className="arrow">
                <div className="inner">
                    <img src={require("../../utils/Images/tunda.png")} alt="" />
                </div>
            </div>
            <div className="content">
                {/* <div
                    className="item1 "
                    onClick={() =>
                        industryId === "null"
                            ? navigate(`/${currentLanguage}/assessment/create_profile`)
                            : (setIsPopupVisible(false),
                                showToast(t("GENERAL.PROFILE_CREATED"), "success"))
                    }
                >
                    {industryId === "null"
                        ? t("GENERAL.COMPLETE_PROFILE")
                        : t("GENERAL.UPDATE_PROFILE")}
                </div> */}
                <div
                    className="item2 "
                    onClick={() => {
                        localStorage.removeItem("userId");
                        localStorage.removeItem("companyId");
                        localStorage.removeItem("adminAccessToken");
                        sessionStorage.clear();
                        navigate("/");
                    }}
                >
                    {t("GENERAL.LOGOUT")}
                </div>
            </div>
        </div>
    );
};
export default UserIconPopup;
