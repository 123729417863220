import React from "react";
import "./Login.scss";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { AssessmentButton, InputField } from "ui-components";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import i18n from "../../i18nConfig";
// import { v4 as uuidv4 } from "uuid";
import { adminLogin } from "../../service/Auth/auth";

const Login: React.FC = () => {
  const { t } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const navigate = useNavigate();

  // register Login Data
  const loginUserData = {
    emailId: "",
    password: "",
  };

  const loginUserSchema = Yup.object().shape({
    emailId: Yup.string()
      .min(3, t("ERROR.FIRST_NAME_THREE_CHAR"))
      .trim()
      .required(t("ERROR.USERNAME_REQUIRED")),
    password: Yup.string(),
  });

  // login applicant
  const handleApplicantLogin = async (
    value: any,
    resetForm?: () => void,
    setSubmitting?: (data: boolean) => any,
  ) => {
    const data = {
      emailId: value?.emailId?.toLowerCase(),
      password: value?.password.trim(),
    };
    try {
      // Set isSubmitting to true to indicate that form submission is in progress
      setSubmitting && setSubmitting(true);
      const adminLoginRes = await adminLogin(data);
      if (adminLoginRes?.status === 200) {
        resetForm && resetForm();
        // const uniqueId = uuidv4();
        localStorage.setItem(
          "adminAccessToken",
          adminLoginRes?.data?.accessToken,
        );
        localStorage.setItem("companyId", adminLoginRes?.data?.companyId);
        localStorage.setItem("userId", adminLoginRes?.data?.userId);
        navigate(`/${currentLanguage}/company-list/`);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // navigate(`/${currentLanguage}/company-list}`);
    } finally {
      // Ensure isSubmitting is set to false, even if an error occurs during submission
      setSubmitting && setSubmitting(false);
    }
  };

  return (
    <div className="userActive">
      <Formik
        initialValues={loginUserData}
        validationSchema={loginUserSchema}
        onSubmit={(value, { resetForm, setSubmitting }) => {
          handleApplicantLogin(value, resetForm, setSubmitting);
        }}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => {
          return (
            <Form>
              <div className="userSec">
                <div className="userContent">
                  <h1 className="userHeading">
                    {/* {t("GENERAL.REGISTER_YOURSELF")} */}
                    Login
                  </h1>
                  <Field name="emailId">
                    {() => (
                      <InputField
                        placeholder={`${t("GENERAL.EMAIL")} *`}
                        onChange={(newValue) =>
                          setFieldValue("emailId", newValue)
                        }
                        value={values.emailId}
                        additionalProps={{ className: "userNameInput" }}
                      />
                    )}
                  </Field>
                  {errors?.emailId && touched?.emailId ? (
                    <div className="errorMessage">{errors?.emailId}</div>
                  ) : null}

                  <Field name="password">
                    {() => (
                      <InputField
                        placeholder={`${t("GENERAL.PASSWORD")} *`}
                        type="password"
                        onChange={(newValue) =>
                          setFieldValue("password", newValue)
                        }
                        value={values.password}
                        additionalProps={{ className: "passwordInput" }}
                      />
                    )}
                  </Field>
                  {errors?.password && touched?.password ? (
                    <div className="errorMessage">{errors?.password}</div>
                  ) : null}

                  <AssessmentButton
                    className="submitBtn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? t("GENERAL.LOADING") : t("GENERAL.SUBMIT")}
                  </AssessmentButton>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
export default Login;
