import { useEffect, useState } from "react";
import { AssessmentButton, Select, showToast, TextEditor } from "ui-components";
// import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
// import AssessmentListingIcon from "../../utils/Images/AssessmentListingIcon";
import "./ViewTicket.scss";
import CreateTicketPopup from "./CreateTicketPopup";
// import SupportTicketListingIcon from "../../utils/Images/SupportTicketListingIcon";
import { getTicketById } from "../../service/SupportTicket/supportTicket";

const ViewTicket = () => {
  // const { t, i18n } = useTranslation();
  // const currentLanguage = i18n.language;
  // const navigate = useNavigate();
  const [showCreateTicket, setShowCreateTicket] = useState<boolean>(false);
  const [ticketDetails, setTicketDetails] = useState<any | null>(null);
  const { ticketId } = useParams();
  useEffect(() => {
    if (ticketId) getTicketByIdHandler();
  }, []);

  async function getTicketByIdHandler() {
    const response = await getTicketById(ticketId);
    if (response?.status === 200) {
      // response: ApiResponse
      setTicketDetails(response?.data);
    } else {
      showToast(response?.data?.customMessage, "error");
    }
  }

  return (
    <>
      <div className="ticketListWrapper">
        <div className="container-fluid mb-4">
          <div className="row">
            <div className="col-md-12">
              <div className="CreatedAssessmentListTitle">
                <h2>
                  Support Tickets
                  <AssessmentButton
                    onClick={() => {
                      setShowCreateTicket(true);
                    }}
                    className="whiteBtn"
                  >
                    Create New
                  </AssessmentButton>
                </h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8" key={Math.random()}>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="ticketBg m-3">
                        <div className="row">
                          <div className="col-md-12 mb-4 ticketHead">
                            <h5>
                              {" "}
                              <b>{ticketDetails?.subject}</b>
                            </h5>
                            <p dangerouslySetInnerHTML={{
                              __html: ` ${ticketDetails?.description}`,
                            }}></p>
                          </div>
                          <div className="col-md-3 mb-3">
                            <h6>Ticket ID</h6>
                            <p> #{ticketDetails?.tokenServiceId}</p>
                          </div>
                          <div className="col-md-3 mb-3">
                            <h6>Created by</h6>
                            <p>User Name</p>
                          </div>
                          {/* <div className="col-md-3 mb-3">
                            <h6>Ticket Type</h6>
                            <p>{ticketDetails?.supportTicketType?.supportTicketTypeName}</p>
                          </div>
                          <div className="col-md-3 mb-3">
                            <h6>Current status</h6>
                            <p> {ticketDetails?.status}</p>
                          </div> */}
                          <div className="col-md-3 mb-3">
                            <h6>Created date</h6>
                            <p>10 Jun 2024, Tuesday 6:11 PM</p>
                          </div>
                          <div className="col-md-3 mb-3">
                            <h6>Last updated</h6>
                            <p>18 Jun 2024, Tuesday 6:11 PM</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-4">
                      <div className="ticketBodyBg m-3">
                        <div className="row">
                          <div className="col-md-12 mb-4 ticketHead">
                            <h5>
                              {" "}
                              <b>Messages (3)</b>
                            </h5>
                            <TextEditor
                              placeholder={"New message"}
                              // onChange={(newValue: any) => {
                              // setFieldValue("description", newValue);
                              // }}
                              onChange={() => { }}
                              value=""
                            />
                          </div>
                          <div className="col-md-12"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4" key={Math.random()}>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 mb-5">
                      <div className="ticketBg m-3">
                        <div className="row">
                          <div className="col-md-12 mb-4 ticketHead">
                            <h5>
                              <b>Ticket details</b>
                            </h5>
                            <hr />
                          </div>
                          <div className="col-md-12 mb-3">
                            <h6>Status</h6>
                            <Select
                              placeholder="Status"
                              options={[]}
                            // options={supportType}
                            // onChange={(newValue: any) => {

                            //   setFieldValue(
                            //     "supportType",
                            //     newValue.target.value,
                            //   );
                            // }}
                            // value={values.supportType}
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <h6>Ticket type</h6>
                            <Select
                              placeholder="Ticket type"
                              options={[]}
                            // options={supportType}
                            // onChange={(newValue: any) => {
                            //   setFieldValue(
                            //     "supportType",
                            //     newValue.target.value,
                            //   );
                            // }}
                            // value={values.supportType}
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <h6>Assign to</h6>
                            <Select
                              placeholder="Assignee"
                              options={[]}
                            // options={supportType}
                            // onChange={(newValue: any) => {
                            //   setFieldValue(
                            //     "supportType",
                            //     newValue.target.value,
                            //   );
                            // }}
                            // value={values.supportType}
                            />
                          </div>
                          {/* <div className="col-md-12 mb-3">
                            <h6>Current status</h6>
                            <p> {ticketDetails?.status}</p>
                          </div>
                          <div className="col-md-12 mb-3">
                            <h6>Created date</h6>
                            <p>10 Jun 2024, Tuesday 6:11 PM</p>
                          </div>
                          <div className="col-md-12 mb-3">
                            <h6>Last updated</h6>
                            <p>18 Jun 2024, Tuesday 6:11 PM</p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateTicketPopup
        showCreateTicket={showCreateTicket}
        label="Create New"
        yesCallback={() => { }}
        noCallback={() => { }}
        isCrossBtn={true}
        handleClose={() => {
          setShowCreateTicket(false);
        }}
      />
    </>
  );
};

export default ViewTicket;
