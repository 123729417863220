import { handleError } from "../../utils/Common/Constant";
import apiClient from "../Connection/Connection";

// to get assessment types
export const getAssessmentTypes = async () => {
  try {
    return (await apiClient.get("assessmentType/getAssessmentTypes"))?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get Role Centric Render
export const getRoleCentricRender = async (component: string) => {
  try {
    return (
      await apiClient.get(
        `assessmentType/getRoleCentricRender?search=${component}`,
      )
    )?.data?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get assessment skills
export const getIndustrySkill = async (
  industriesId: string,
  searchSkill: string,
  currentPage: number,
  pageSize: number,
) => {
  try {
    return (
      await apiClient.get(
        `industries/getIndustrySkillMapping?industriesId=${industriesId}&search=${searchSkill}&currentPage=${currentPage}&pageSize=${pageSize}`,
      )
    )?.data?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get assessment existing template
export const getPublicAndCompaniesPrivateAssessment = async (
  industriesId: string,
  companyId: string,
  search: string,
  currentPage: number,
  pageSize: number,
) => {
  try {
    return (
      await apiClient.get(
        `assessment/getPublicAndCompaniesPrivateAssessment?industriesId=${industriesId}&companyId=${companyId}&search=${search}&currentPage=${currentPage}&pageSize=${pageSize}`,
      )
    )?.data?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get system preference
export const getFrontEndSystemPreference = async () => {
  try {
    return (await apiClient.get("systemPreference/getSystemPreference"))?.data
      ?.data;
  } catch (error) {
    handleError(error);
  }
};

//  to delete assessment
export const deleteAssessment = async (
  assessmentId: string | number | null,
) => {
  try {
    const res = await apiClient.delete(
      `assessment/deleteDraftedAssessment?assessmentId=${assessmentId}`,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};

//  for update Assessment Status
export const updateAssessmentStatus = async (data: any) => {
  try {
    const res = await apiClient.put("assessment/updateAssessmentStatus", data);
    return res;
  } catch (error) {
    handleError(error);
  }
};

//  for reopen Deleted Assessment Status
export const reopenDeletedAssessment = async (data: any) => {
  try {
    const res = await apiClient.put("assessment/reopenDeletedAssessment", data);
    return res;
  } catch (error) {
    handleError(error);
  }
};

// company login
export const companyApplicant = async (data: any) => {
  try {
    const res = await apiClient.post("company/companyLogIn", data);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};
