import moment from "moment";
import { showToast } from "ui-components";
import { t } from "i18next";

import { v4 as uuidv4 } from "uuid";
import { getSessionStorageItem } from "../SessionStorageMethod/getSessionStorageItem";
import { setSessionStorageItem } from "../SessionStorageMethod/setSessionStorageItem";

// check component disability
export const isDisable = (functionBehavior: any) => {
  return functionBehavior === "disable" ? true : false;
};

// check component hidden or not
export const isHide = (functionBehavior: any) => {
  return functionBehavior === "hide" ? false : true;
};

export const addDisableCss = (functionBehavior: any) => {
  return functionBehavior === "disable" ? "addDisableCss" : "";
};

// find value for render
export const findValuesForKey = (
  arrayData: any,
  keyName: string,
  keyToFind: string | number,
) => {
  // Extract all scope arrays
  const filteredArray = (arrayData || [])
    .map((item: any) => item[keyName])
    .filter(Boolean);

  // Function to find the value for a specific key in a scope array
  const findValueForKey = (filterArray: any, key: string | number) => {
    const scopeItem = filterArray.find((item: any) => key in item);
    return scopeItem ? scopeItem[key] : undefined;
  };

  // Iterate through each scope array and find the value for the specified key
  const valuesForGivenKey = filteredArray
    .map((filterArray: any) => findValueForKey(filterArray, keyToFind))
    .filter(Boolean);
  return valuesForGivenKey[0] || null;
};

export function determinePageType(url: string) {
  try {
    const pathname = url.toLowerCase();
    if (pathname.includes("scope")) {
      return 1; // Scope available in URL
    } else if (pathname.includes("setting")) {
      return 2; // Setting available in URL
    } else if (pathname.includes("review")) {
      return 3; // Review available in URL
    } else {
      return 1; // None of the keywords found
    }
  } catch (error) {
    console.error("Invalid URL:", error);
    return 1; // Invalid URL
  }
}

// Custom validation function
export const validateDuplicateDifficulty = (arr: any) => {
  const uniqueSkillLevelPairs = new Set();
  for (const item of arr) {
    const pair = `${item.skillName}_${item.difficultyLevel}`;
    if (uniqueSkillLevelPairs.has(pair)) {
      return true; // Duplicate pair found
    }
    uniqueSkillLevelPairs.add(pair);
  }
  return false; // No duplicate pairs found
};
// Validate the array
export const initialScopeData = {
  type: getSessionStorageItem("Type") || "",
  titleHeadContent: getSessionStorageItem("titleHeadContent") || "",
};

export const initialSettingData = {
  type: getSessionStorageItem("Type") || "",
  titleHeadContent: getSessionStorageItem("titleHeadContent") || "",
  positionsCount: 0,
};

export const initialInviteData = {
  inviteEmail: "",
};

// user login User Data
export const loginUserData = {
  email: "",
  password: "",
};

export const minuteOptions = (minLabel: string) => {
  const options = [];
  for (let i = 1; i <= 15; i++) {
    options.push({ value: i, label: `${i} ${minLabel}${i !== 1 ? "s" : ""}` });
  }
  return options;
};

export const initialReviewData = {
  type: getSessionStorageItem("Type") || "",
  titleHeadContent: getSessionStorageItem("titleHeadContent") || "",
};

// check the anwer check while creating custom questions
export const isAnswerSetValid = (data: any, additionCheck: boolean = false) => {
  if (data?.type == "Single" || data?.type == "Multiple") {
    if (Array.isArray(data.answerSet)) {
      for (const answer of data.answerSet) {
        // Check if label has some value or image is present with some value
        if (
          (answer?.label &&
            answer?.label.trim() !== "" &&
            (additionCheck ? answer?.label?.length < 1000 : true)) ||
          (answer?.image !== null && answer?.image !== undefined)
        ) {
          // Move to the next object if the condition is met
          continue;
        } else {
          // If label has no value or image is not present and has a null value, return false
          return false;
        }
      }
    } else {
      // If answerSet is not an array, return false
      return false;
    }
  }
  if (data.type === "Essay" || data.type === "Video") {
    // Check if answerSet is a non-empty string
    if (
      typeof data.answerSet === "string" &&
      data.answerSet.trim() !== "" &&
      (additionCheck ? data?.answerSet?.length < 1000 : true)
    ) {
      return true;
    } else {
      // If answerSet is not a valid string, return false
      return false;
    }
  }

  // If all objects pass the conditions, return true
  return true;
  // }
};

interface InputValue {
  id: string;
  label: string;
  selected: boolean;
  image: string | null;
}

interface InputValueOne {
  Single: InputValue[];
  Multiple: InputValue[];
  Essay: string;
  Video: string;
}

// check Should Add InputValue
export function checkShouldAddInputValue(
  inputValueOne: InputValueOne,
  inputValueType: keyof InputValueOne,
): boolean {
  const values = inputValueOne[inputValueType];
  if (Array.isArray(values)) {
    return values.every(
      (item) =>
        item.label.trim() !== "" ||
        (item.image !== undefined && item.image !== null),
    );
  }
  return false;
}

// Transform existing tempalte data
export const transformExistingTempalteData = (data: any) => {
  const totalQuestion = (assessmentSkills: any) => {
    const totalQuestions = assessmentSkills?.reduce(
      (accumulator: any, currentValue: any) =>
        accumulator + currentValue?.totalQuestions,
      0,
    );
    return totalQuestions;
  };

  const tranformData = [];
  if (data?.length) {
    for (let i = 0; i < data?.length; i++) {
      const tempData = {
        subjectFieldSet: data[i]?.assessmentSkills?.map((item: any) => ({
          skillId: item?.skillId,
          skillIcon: item?.skillIcon,
          difficultyLevel: item?.difficultyLevel,
          title: item?.skillName,
          questionsCount: item?.totalQuestions,
          indiAssessTime: `${item?.totalQuestions * 3} min(s)`,
        })),
        assessmentTitle: data[i]?.title,
        aboutAssess: "About test 01",
        description: data[i]?.description,
        skills: data[i]?.assessmentSkills?.length,
        experienceLevel: `${data[i]?.minimumExperience ?? 3} Year(s) - ${
          data[i]?.maximumExperience ?? 5
        } Year(s)`,
        timeLimit: `${totalQuestion(data[i]?.assessmentSkills) * 3} min(s)`,
        questions: totalQuestion(data[i]?.assessmentSkills),
        assessmentId: data[i]?.assessmentId,
        isPublic: data[i]?.isPublic,
      };
      tranformData.push(tempData);
    }
    return tranformData;
  }
  return [];
};

// tranform answer set
export const transformAnswerSet = (inputData: any) => {
  const updateAssessmentQuestions = [];
  for (let i = 0; i < inputData?.length; i++) {
    if (inputData[i].type === "Essay") {
      const essayData = inputData[i];
      essayData.customQuestion = true;
      essayData.attachment = inputData[i]?.image;
      delete inputData[i]?.image;
      updateAssessmentQuestions.push(essayData);
    } else if (inputData[i]?.type === "Video") {
      const videoData = inputData[i];
      videoData.customQuestion = true;
      videoData.attachment = inputData[i]?.image;
      delete inputData[i]?.image;
      updateAssessmentQuestions.push(videoData);
    } else if (inputData[i]?.type !== "template") {
      const answerOptions = inputData[i]?.answerSet
        .filter((item: any) => item) // Filter items where selected is false
        .map((item: any) => ({
          option: item?.label,
          attachment: item?.image || null,
        })); // Map to the desired format

      const correctOptions = inputData[i]?.answerSet
        .filter((item: any) => item?.selected) // Filter items where selected is true
        .map((item: any) => ({
          options: item?.label,
          attachment: item?.image || null,
        })); // Map to the desired format
      const UpdateObj = {
        question: inputData[i]?.question || null,
        type: inputData[i]?.type || null,
        attachment: inputData[i]?.image || null,
        timeLimit: inputData[i]?.timeLimit || null,
        totalQuestions: inputData[i]?.questionsCount,
        customQuestion: true,
        answerOptions,
        correctOptions,
      };
      updateAssessmentQuestions.push(UpdateObj);
    } else {
      const {
        difficultyLevel,
        indiAssessTime,
        questionsCount,
        totalQuestions,
        skillId,
        skillLevel,
        skillName,
        title,
        type,
      } = inputData[i];
      const finalObj = {
        difficultyLevel,
        indiAssessTime,
        totalQuestions: questionsCount ?? totalQuestions,
        skillId,
        skillLevel,
        skillName,
        title,
        type,
      };
      updateAssessmentQuestions.push(finalObj);
    }
  }
  return updateAssessmentQuestions;
};
// function to get assessement path as per steps
export function getAssessmentPath(
  stepNumber: number,
  tempUniqueId: undefined | string | number,
  currentLanguage: string,
  editedParams?: string | null,
): string {
  switch (stepNumber) {
    case 1:
      return `/${currentLanguage}/assessment/scope/${tempUniqueId}${editedParams ? "?edited=true" : ""}`;
    case 2:
      return `/${currentLanguage}/assessment/settings/${tempUniqueId}${editedParams ? "?edited=true" : ""}`;
    case 3:
      return `/${currentLanguage}/assessment/review/${tempUniqueId}${editedParams ? "?edited=true" : ""}`;
    default:
      // Handle other cases if needed
      return `/${currentLanguage}/assessment/default`;
  }
}

export function calculateMinusAmount(
  increasingValue: number,
  lastCount: number,
) {
  return lastCount - increasingValue;
}

// interface Item {
//   skillId: number | string;
//   id?: number | string;
//   questionsCount?: number | undefined;
//   totalQuestions?: number | undefined;
// }

// export function matchArrays<T extends Item>(arr1: T[], arr2: T[]): any {
//   if ((arr1 || []).length !== (arr2 || []).length) {
//     return false;
//   }

//   // Filter out items where both skillId and id are undefined
//   const filteredArr2 = (arr2 || []).filter(
//     (item) =>
//       item.skillId !== undefined ||
//       item.id !== undefined ||
//       item.questionsCount !== undefined ||
//       item.totalQuestions !== undefined,
//   );

//   // Convert filteredArr2 to a map for faster lookup
//   const map2: Map<string | number, T> = new Map(
//     filteredArr2.map((item) => [
//       item.skillId ?? (item.id as string | number),
//       item,
//     ]),
//   );

//   // Check if all IDs from arr1 are present in arr2 and corresponding questionsCount or totalQuestions match
//   return (arr1 || []).every((item) => {
//     const matchedItem = map2.get(item.skillId ?? item.id);
//     if (matchedItem) {
//       return (
//         (item.questionsCount === undefined ||
//           item.questionsCount === matchedItem.questionsCount) &&
//         (item.totalQuestions === undefined ||
//           item.totalQuestions === matchedItem.totalQuestions)
//       );
//     }
//     return false; // If the item is not found in map2
//   });
// }
// Utility function to format the timer value into minutes and seconds
export const formatTime = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export const handleKeyPressSearch = (
  e: React.KeyboardEvent<HTMLInputElement>,
) => {
  if (e.key === "Enter") {
    e.preventDefault(); // Prevent default form submission on Enter key
  }
};

interface ItemId {
  id: number;
  // Other properties if any
}

export function sortArr2ByArr1(arr1: ItemId[], arr2: ItemId[]): ItemId[] {
  if ((arr1 || []).length !== (arr2 || []).length) {
    return arr2;
  }

  // Create a map to store the index of each ID in arr1
  const idIndexMap: Map<number, number> = new Map();
  (arr1 || []).forEach((item, index) => {
    idIndexMap.set(item.id, index);
  });

  // Sort arr2 based on the index of IDs in arr1
  (arr2 || []).sort((a, b) => {
    const indexA = idIndexMap.get(a.id);
    const indexB = idIndexMap.get(b.id);
    // If either ID is not found in arr1, sort them based on their original order
    if (indexA === undefined && indexB === undefined) {
      return 0; // No change in order
    } else if (indexA === undefined) {
      return 1; // Move b ahead of a
    } else if (indexB === undefined) {
      return -1; // Move a ahead of b
    }
    return indexA - indexB; // Sort based on the index in arr1
  });

  return arr2;
}

// text to speak function
export function TextToSpeakMessage(message: string, callback: () => void) {
  const speech: any = new SpeechSynthesisUtterance(message);
  speech.voice = window.speechSynthesis.getVoices()[10];
  speechSynthesis.speak(speech);
  // Call the callback function after the message has been spoken
  speech.onend = callback;
}

export const updateProgressBarAndColors = (
  questionSet: any[],
  currentQuestionIndex: number,
  questionData: any,
  assessmentDetailsData: any,
  setProgressBar: (callback: any) => void,
  setSubQuestionColors: (colors: string[]) => void,
  setApiError: (error: boolean) => void,
) => {
  // to calculate progress bar width
  const progressBar = (currentQuestionIndex: number, totalQuestion: number) => {
    const equalWidth = 100 / (totalQuestion - 1);
    const widthPerCal = equalWidth * currentQuestionIndex;
    return widthPerCal - equalWidth;
  };
  if (questionSet?.length > currentQuestionIndex) {
    if (questionData?.data?.currentQuestion !== 1) {
      setProgressBar((prev: any) => {
        const updateData =
          prev.progressbar < 100
            ? {
                progressbar: progressBar(
                  questionData?.data?.currentQuestion,
                  assessmentDetailsData?.totalQuestions,
                ),
                index: prev.index + 1,
              }
            : prev;
        return updateData;
      });
    }
    if (assessmentDetailsData?.applicationStatus === "Initiated") {
      const initialUpdateData = Array.from(
        { length: questionSet.length },
        (_, index) =>
          index < questionData?.data?.currentQuestion ? "#35d4ae" : "#c7c7c7",
      );
      const updatedColors = [...initialUpdateData];
      setSubQuestionColors(updatedColors);
    } else {
      const initialUpdateData = Array.from(
        { length: questionSet.length },
        (_, index) => (index === 0 ? "#35d4ae" : "#c7c7c7"),
      );
      if (questionData?.data?.currentQuestion > 1) {
        const updatedColors = Array.from(
          { length: questionSet.length },
          (_, index) =>
            index < questionData?.data?.currentQuestion
              ? "#35d4ae"
              : initialUpdateData[index],
        );
        setSubQuestionColors(updatedColors);
      } else {
        setSubQuestionColors(initialUpdateData);
      }
    }
    setApiError(false);
  }
};
export const convertDate = (
  dateTime: string,
  withComma?: boolean,
  withTime?: boolean,
) => {
  const days: { [key: string]: string } = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
    Sun: "Sunday",
  };
  const dateFormat = moment(dateTime).format("llll").split(",");
  const year = dateFormat[2].split(" ")[1];
  const time = dateFormat[2].split(" ")[2] + " " + dateFormat[2].split(" ")[3];
  const date = dateFormat[1].split(" ")[2];
  const month = dateFormat[1].split(" ")[1];
  const day = dateFormat[0];
  if (withComma) {
    return `${date} ${month} ${year}, ${days[day]}`;
  } else if (withTime) {
    return `${date} ${month} ${year}, ${days[day]} ${time}`;
  } else {
    return `${date} ${month} ${year} (${days[day]})`;
  }
};

// copy to clipbord funtion
export const copyToClipboard = (ref: any, url: string, text?: string) => {
  if (ref.current && navigator.clipboard) {
    // Use the Clipboard API
    navigator.clipboard
      .writeText(url)
      .then(() => {
        showToast(text ? text : t("INVITE.COPY_SUCCESS"), "success");
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((_err) => {
        showToast(t("INVITE.COPY_FAILED"), "error");
      });
  }
};

interface Item {
  value: string | number | undefined;
  label: string;
  option?: any;
}

interface Iobject {
  value: string | number | undefined;
  label: string;
}

export async function getLabelByValue(
  arr: Item[],
  targetValue: string | number,
): Promise<Iobject | undefined> {
  const item = arr.find((item) => item.value === targetValue);
  return item ? item : undefined;
}
// decode text
export function convertToText(value: string) {
  if (value === undefined || value === null) {
    return "";
  } else {
    return atob(value);
  }
}

interface ISkillData {
  skillName: string;
  skillIcon: string;
  difficultyLevel: string;
  totalQuestions: number;
}

// manage the duplicate skill and mention the difficultyLevel
export function manageDuplicateSkills(data: ISkillData[]): any {
  if (data) {
    // IMPORTANT (give the difficultyLevel in case of duplicate)
    // const duplicateEntries: ISkillData[] = (data || []).filter(
    //   (entry, index) => {
    //     return (
    //       (data || []).findIndex(
    //         (item) => item.skillName === entry.skillName,
    //       ) !== index
    //     );
    //   },
    // );

    // (data || []).forEach((entry) => {
    //   if (
    //     duplicateEntries.some(
    //       (dupEntry) => dupEntry.skillName === entry.skillName,
    //     )
    //   ) {
    //     entry.skillName += ` (${entry.difficultyLevel})`;
    //   }
    // });
    // return data ?? [];
    return (
      data.map((skill) => ({
        ...skill,
        skillName: `${skill.skillName} - ${skill.difficultyLevel}`,
      })) ?? []
    );
  } else {
    return null;
  }
}

export function extractLanguage(url: string): string | null {
  const parts = url.split("/");
  if (parts.length < 3) {
    return null; // URL doesn't have enough parts
  }
  return parts[1]; // Return the second part of the URL
}
// start edited assessment data
// transform edited assessment data
export const editedExistingformData = (DataJson: any) => {
  const existingTemplateData = {
    subjectFieldSet: DataJson?.data?.assessmentSkills?.map((item: any) => ({
      skillId: item?.skillId,
      skillIcon: item?.skillIcon,
      difficultyLevel: item?.difficultyLevel,
      title: item?.skillName,
      questionsCount: item?.totalQuestions,
      indiAssessTime: `${item?.totalQuestions * 3} min(s)`,
    })),
    assessmentTitle: DataJson?.data?.title,
    aboutAssess: "About test 01",
    description: DataJson?.data?.description,
    skills: DataJson?.data?.assessmentSkills?.length,
    experienceLevel: `${DataJson?.data?.minimumExperience ?? 3} Year(s) - ${
      DataJson?.data?.maximumExperience ?? 5
    } Year(s)`,
    timeLimit: `${DataJson?.data?.totalTime} min(s)`,
    questions: DataJson?.data?.totalQuestions,
    assessmentId: DataJson?.data?.assessmentId,
    isPublic: DataJson?.data?.isPublic,
  };

  return existingTemplateData;
};

export const editedformData = (Json: any, pathName?: string) => {
  setSessionStorageItem(
    "createTemplate",
    Json?.data?.templateId ? "EXISTING" : "OWN",
  );
  setSessionStorageItem("Type", Json?.data?.assessmentTypeId || "");
  setSessionStorageItem("titleHeadContent", Json?.data?.title || "");
  setSessionStorageItem("totalQuestionTime", Json?.data?.totalTime);
  setSessionStorageItem("totalQuestionCount", Json?.data?.totalQuestions);
  if (pathName === "setting") {
    setSessionStorageItem("minimumExperience", Json?.data?.minimumExperience);
    setSessionStorageItem("maximumExperience", Json?.data?.maximumExperience);
    setSessionStorageItem("noOfPositions", Json?.data?.totalPositions);
    setSessionStorageItem("description", Json?.data?.description);
    setSessionStorageItem("instruction", Json?.data?.instructions);
    setSessionStorageItem("cutOff", Json?.data?.cutOff);
  } else if (pathName === "review") {
    setSessionStorageItem(
      "createQuestionData",
      reverseTransformAnswerSet(Json?.data?.assessmentCustomQuestions || []),
    );

    setSessionStorageItem("minimumExperience", Json?.data?.minimumExperience);
    setSessionStorageItem("maximumExperience", Json?.data?.maximumExperience);
    setSessionStorageItem("noOfPositions", Json?.data?.totalPositions);
    setSessionStorageItem("cutOff", Json?.data?.cutOff);
    setSessionStorageItem("evaluation", Json?.data?.evaluationTypes);
  } else {
    setSessionStorageItem(
      "createQuestionData",
      reverseTransformAnswerSet(Json?.data?.assessmentCustomQuestions || []),
    );
  }
};

export const drafteditedformData = (Json: any, pathName?: string) => {
  setSessionStorageItem(
    "createTemplate",
    Json?.createAssessmentJson?.isTemplate,
  );
  setSessionStorageItem(
    "Type",
    Json?.createAssessmentJson?.scope?.assessmentType || "",
  );
  setSessionStorageItem(
    "titleHeadContent",
    Json?.createAssessmentJson?.scope?.title || "",
  );
  setSessionStorageItem(
    "totalQuestionTime",
    Json?.createAssessmentJson?.totalTime,
  );
  setSessionStorageItem(
    "totalQuestionCount",
    Json?.createAssessmentJson?.totalQuestions,
  );
  if (pathName === "setting") {
    setSessionStorageItem(
      "ownPreview",
      Json?.createAssessmentJson?.scope?.assessmentSkill || [],
    );
    setSessionStorageItem(
      "existingPreview",
      Json?.createAssessmentJson?.scope?.existingPreview || [],
    );
    setSessionStorageItem(
      "createQuestionData",
      Json?.createAssessmentJson?.scope?.AssessmentCustomQuestions || [],
    );
    setSessionStorageItem(
      "minimumExperience",
      Json?.createAssessmentJson?.setting?.minimumExperience,
    );
    setSessionStorageItem(
      "maximumExperience",
      Json?.createAssessmentJson?.setting?.maximumExperience,
    );
    setSessionStorageItem(
      "description",
      Json?.createAssessmentJson?.setting?.description || "",
    );
    setSessionStorageItem(
      "noOfPositions",
      Json?.createAssessmentJson?.setting?.noOfPositions,
    );
    setSessionStorageItem(
      "cutOff",
      Json?.createAssessmentJson?.setting?.cutOff,
    );
    setSessionStorageItem(
      "instruction",
      Json?.createAssessmentJson?.setting?.instructions || "",
    );
  } else if (pathName === "review") {
    setSessionStorageItem(
      "ownPreview",
      Json?.createAssessmentJson?.scope?.assessmentSkill || [],
    );
    setSessionStorageItem(
      "existingPreview",
      Json?.createAssessmentJson?.scope?.existingPreview || [],
    );
    setSessionStorageItem(
      "createQuestionData",
      Json?.createAssessmentJson?.scope?.AssessmentCustomQuestions || [],
    );
    setSessionStorageItem(
      "minimumExperience",
      Json?.createAssessmentJson?.setting?.minimumExperience,
    );
    setSessionStorageItem(
      "maximumExperience",
      Json?.createAssessmentJson?.setting?.maximumExperience,
    );
    setSessionStorageItem(
      "noOfPositions",
      Json?.createAssessmentJson?.setting?.noOfPositions,
    );
    setSessionStorageItem(
      "cutOff",
      Json?.createAssessmentJson?.setting?.cutOff,
    );
    setSessionStorageItem(
      "evaluation",
      Json?.createAssessmentJson?.review?.evaluationTypes,
    );
  } else {
    setSessionStorageItem(
      "ownPreview",
      Json?.createAssessmentJson?.scope?.assessmentSkill || [],
    );
    setSessionStorageItem(
      "existingPreview",
      Json?.createAssessmentJson?.scope?.existingPreview || [],
    );
    setSessionStorageItem(
      "createQuestionData",
      Json?.createAssessmentJson?.scope?.AssessmentCustomQuestions || [],
    );
  }
};
// transform edited custom question
export const reverseTransformAnswerSet = (updateAssessmentQuestions: any[]) => {
  const inputData = [];

  for (const item of updateAssessmentQuestions) {
    if (item.type === "Essay" || item.type === "Video") {
      inputData.push({
        ...item,
        answerSet: [],
        image: item.attachment || null,
        id: Math.random(),
      });
    } else {
      const answerSet = [];
      for (const option of item.answerOptions) {
        answerSet.push({
          label: option.option,
          image: option.attachment || null,
          selected: false,
          id: Math.random(),
        });
      }
      for (const option of item.correctOptions) {
        answerSet.push({
          label: option.option,
          image: option.attachment || null,
          selected: true,
          id: Math.random(),
        });
      }
      inputData.push({
        id: Math.random(),
        question: item.question,
        type: item.type,
        timeLimit: item.timeLimit,
        answerSet,
        image: item.attachment || null,
      });
    }
  }
  return inputData;
};
// end edited assessment data

// start generate uuid
export const generateUUID = () => {
  const hexDigits = "0123456789abcdef";
  const segments = [8, 4, 4, 4, 12];

  return segments
    .map((segment) =>
      Array.from({ length: segment }, () =>
        hexDigits.charAt(Math.floor(Math.random() * 16)),
      ).join(""),
    )
    .join("-");
};
// end generate uuid
// start pascal case
export const toPascalCase = (str: string): string => {
  return str.replace(/(\w)(\w*)/g, (_, firstChar, restOfWord) => {
    return firstChar.toUpperCase() + restOfWord.toLowerCase();
  });
};
// end pascal case

// extract path from url
export function extractPathFromUrl(url: string): string | null {
  // eslint-disable-next-line no-useless-escape
  const match = url.match(/\/a\/([^\/?#]+)/);
  return match ? match[0].substring(1) : null;
}

// calculate time in min, hour, month, year ago
export function getTimeDifference(
  inputTime: string,
  minuteText: string,
  hourText: string,
  dayText: string,
  monthText: string,
  yearText: string,
  agoText: string,
  justNowText: string,
) {
  const currentTime = moment();
  if (!inputTime) {
    return undefined;
  }
  const diffMinutes = currentTime.diff(inputTime, "minutes");
  const diffHours = currentTime.diff(inputTime, "hours");
  const diffDays = currentTime.diff(inputTime, "days");
  const diffMonths = currentTime.diff(inputTime, "months");
  const diffYears = currentTime.diff(inputTime, "years");

  if (diffMinutes < 1) {
    return `${justNowText}`;
  } else if (diffMinutes < 60) {
    return `${`${diffMinutes} ${minuteText}`} ${agoText}`;
  } else if (diffHours < 24) {
    return `${`${diffHours} ${hourText}`} ${agoText}`;
  } else if (diffDays < 30) {
    return `${`${diffDays} ${dayText}`} ${agoText}`;
  } else if (diffMonths < 12) {
    return `${`${diffMonths} ${monthText}`} ${agoText}`;
  } else {
    return `${`${diffYears} ${yearText}`} ${agoText}`;
  }
}

// handle error while calling api
export function handleError(error: any) {
  if (error?.customMessage) {
    showToast(error?.customMessage, "error");
  } else {
    console.log("error", error);
  }
}

// if we have uniqueId then use that otherwise use new
export const foundUniqueId = (id: string | undefined | null) => {
  if (id) {
    return id;
  } else {
    const uniqueId = uuidv4();
    localStorage.setItem("tempUniqueId", uniqueId);
    return uniqueId;
  }
};

// check Unique Id Match
export const checkUniqueIdMatch = (
  firstId: string | null | undefined,
  secondId: string | null | undefined,
) => {
  return (firstId && secondId && firstId !== secondId) || false;
};

export const checkObjectValues = (obj: any): boolean => {
  for (const key in obj) {
    if (obj[key]) {
      return true;
    }
  }
  return false;
};
